<template>
  <v-card flat class="mb-0 step-1" color="lighten-1" min-height="400px">
    <v-card-text class="px-md-12 px-sm-1">
      <v-form v-model="valid">
        <v-row dense class="mx-md-12 mx-sm-1">
          <v-col :cols="mdAndUp ? 6 : 12" class="mb-2">
            <label class="primary--text subtitle-2 steps-label">
              Type
              <span class="fw-100 fs-13 red--text" v-if="is_editting">
                (Changing type not allowed)
              </span>
            </label>
            <v-radio-group
              row
              :disabled="is_editting"
              dense
              class="mt-1"
              hide-details="auto"
              v-model="type"
            >
              <v-radio
                label="One-time"
                value="one-time"
                style="margin-right: 40px"
              ></v-radio>
              <v-radio
                label="Weekly"
                value="weekly"
                style="margin-right: 40px"
              ></v-radio>
              <!-- <v-radio label="Bi-Monthly" value="bi-monthly"></v-radio> -->
              <v-radio label="Monthly" value="monthly"></v-radio>
              <!-- <v-radio label="Yearly" value="yearly"></v-radio> -->
            </v-radio-group>
          </v-col>
          <v-col :cols="mdAndUp ? 2 : 12" class="mb-2">
            <label class="primary--text subtitle-2 steps-label">
              Send invoice email?
            </label>
            <v-radio-group
              class="mt-1"
              row
              dense
              hide-details="auto"
              v-model="send_email"
            >
              <v-radio label="Yes" value="yes"></v-radio>
              <v-radio label="No" value="no"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col :cols="mdAndUp ? 4 : 12" class="mb-2">
            <label class="primary--text subtitle-2 steps-label">
              Currency
            </label>
            <v-select
              :items="currencies"
              return-object
              outlined
              item-text="code"
              placeholder="Select invoice currency"
              dense
              v-model="currency"
              hide-details="auto"
            >
              <template v-slot:item="{ item }">
                <span>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="caption text-right">
                        <span class="subtitle-2">{{ item.code }}</span>
                        <span class="ml-3">({{ item.symbol }})</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col :cols="mdAndUp ? 8 : 12" class="mb-2">
            <label class="primary--text subtitle-2 steps-label"> Title </label>
            <v-text-field
              :rules="requiredRules"
              outlined
              v-model="title"
              placeholder="Enter invoice title"
              clearable
              clear-icon="mdi-close-circle-outline"
              hide-details="auto"
              dense
            ></v-text-field>
          </v-col>
          <v-col :cols="mdAndUp ? 4 : 12" class="mb-2">
            <label class="primary--text subtitle-2 steps-label">
              Attach Project
              <span class="fw-100 fs-13 red--text" v-if="is_editting">
                (Changing project not allowed)
              </span>
            </label>
            <v-select
              :items="projects"
              item-value="id"
              outlined
              :disabled="is_editting"
              item-text="project_name"
              dense
              placeholder="Optional, Select project"
              clearable
              @click:clear="project_id = null"
              clear-icon="mdi-close-circle-outline"
              v-model="project_id"
              hide-details="auto"
            >
            </v-select>
          </v-col>
          <v-col
            :cols="mdAndUp ? 4 : 12"
            class="mb-2"
            v-if="type === 'one-time'"
          >
            <label class="primary--text subtitle-2 steps-label">
              Billing Date
            </label>
            <DatePicker
              :has-buttons="false"
              append-icon="date_range"
              class="textfield"
              outlined
              dense
              hide-details="auto"
              :max="due_date"
              flat
              v-model="billing_date"
              placeholder="Select Date"
            ></DatePicker>
          </v-col>
          <v-col :cols="mdAndUp ? 4 : 12" class="mb-2" v-else>
            <label class="primary--text subtitle-2 steps-label">
              Billing Date Recurring
            </label>
            <v-select
              :items="recurring_list"
              item-text="name"
              item-value="value"
              append-icon="date_range"
              dense
              return-object
              outlined
              placeholder="Select interval"
              v-model="billing_date_recurring"
              hide-details="auto"
            ></v-select>
          </v-col>
          <v-col
            :cols="mdAndUp ? 4 : 12"
            class="mb-2"
            v-if="type === 'one-time'"
          >
            <label class="primary--text subtitle-2 steps-label">
              Due Date <sup class="text--red">*</sup>
            </label>
            <DatePicker
              :has-buttons="false"
              append-icon="date_range"
              class="textfield"
              outlined
              dense
              :min="billing_date"
              hide-details="auto"
              flat
              v-model="due_date"
              placeholder="Select Date"
            ></DatePicker>
          </v-col>
          <v-col :cols="mdAndUp ? 4 : 12" class="mb-2" v-else>
            <label class="primary--text subtitle-2 steps-label">
              Due Date Recurring<sup class="text--red">*</sup>
            </label>
            <v-select
              :items="recurring_list"
              item-text="name"
              item-value="value"
              append-icon="date_range"
              return-object
              dense
              placeholder="Select interval"
              outlined
              v-model="due_date_recurring"
              hide-details="auto"
            ></v-select>
          </v-col>
          <v-col :cols="mdAndUp ? 4 : 12" class="mb-2">
            <label class="primary--text subtitle-2 steps-label">
              Attach Service
            </label>
            <v-select
              :items="services"
              item-value="id"
              outlined
              item-text="name"
              dense
              placeholder="Optional, Select service"
              clearable
              clear-icon="mdi-close-circle-outline"
              v-model="service_id"
              hide-details="auto"
            >
            </v-select>
          </v-col>

          <v-col :cols="mdAndUp ? 4 : 12" class="mb-2">
            <label class="primary--text subtitle-2 steps-label">
              Billed From <sup class="text--red">*</sup>
            </label>
            <v-select
              :items="usersForFrom"
              return-object
              outlined
              item-text="fullname"
              item-value="id"
              dense
              placeholder="Select billing from"
              :rules="requiredRules"
              v-model="billable_from_id"
              hide-details="auto"
            >
              <template v-slot:item="{ item }">
                <span>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-img
                        :lazy-src="settings.loader"
                        width="30"
                        :src="item.image_url"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-title> {{ item.fullname }} </v-list-item-title>
                  </v-list-item>
                </span>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip label color="transparent" class="border-0">
                  <v-avatar class="mr-2">
                    <v-img
                      :lazy-src="settings.loader"
                      width="30"
                      :src="item.image_url"
                    ></v-img>
                  </v-avatar>
                  <span> {{ item.fullname }} </span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col :cols="mdAndUp ? 4 : 12" class="mb-2">
            <label class="primary--text subtitle-2 steps-label">
              Billed To <sup class="text--red">*</sup>
            </label>
            <v-select
              :items="usersForTo"
              return-object
              outlined
              item-text="fullname"
              item-value="id"
              dense
              placeholder="Select billing recipient"
              :rules="requiredRules"
              v-model="billable_to_id"
              hide-details="auto"
            >
              <template v-slot:item="{ item }">
                <span>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-img
                        :lazy-src="settings.loader"
                        width="30"
                        :src="item.image_url"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-title>
                      {{ item.fullname }}
                    </v-list-item-title>
                  </v-list-item>
                </span>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip label color="transparent" class="border-0">
                  <v-avatar class="mr-2">
                    <v-img
                      :lazy-src="settings.loader"
                      width="30"
                      :src="item.image_url"
                    ></v-img>
                  </v-avatar>
                  <span> {{ item.fullname }} </span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col :cols="mdAndUp ? 4 : 12" class="mb-2">
            <label class="primary--text subtitle-2 steps-label"> Terms </label>
            <v-textarea
              outlined
              placeholder="Optional, Enter terms and conditions of this invoice"
              clearable
              clear-icon="mdi-close-circle-outline"
              auto-grow
              v-model="terms"
              :rows="1"
              hide-details="auto"
              dense
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DatePicker from '@/common/DatePicker.vue'
export default {
  name: 'InvoiceStep1',
  components: { DatePicker },
  data: () => ({
    valid: false,
    open_preview: false
  }),
  created() {
    this.fetchUsers()
    this.fetchProjects()
    this.fetchServices()
    this.fetchTemplates()
  },
  computed: {
    ...mapGetters('invoice', [
      'templates',
      'services',
      'projects',
      'orders',
      'users',
      'is_editting',
      'currencies',
      'invoice',
      'monthly_list',
      'weekly_list'
    ]),
    usersForFrom() {
      if (!this.billable_to_id) {
        return this.users
      }
      return this.users.filter((i) => i.id !== this.billable_to_id)
    },
    usersForTo() {
      if (!this.billable_from_id) {
        return this.users
      }
      return this.users.filter((i) => i.id !== this.billable_from_id)
    },
    recurring_list() {
      return this.type === 'weekly' ? this.weekly_list : this.monthly_list
    },
    currency: {
      get() {
        return this.$store.getters['invoice/currency']
      },
      set(val) {
        this.$store.commit('invoice/set_currency', val.code)
        this.$store.commit('invoice/set_currency_symbol', val.symbol)
      }
    },
    send_email: {
      get() {
        return this.$store.getters['invoice/props'].send_email
      },
      set(val) {
        this.$store.commit('invoice/set_props_key', {
          key: 'send_email',
          value: val
        })
      }
    },
    title: {
      get() {
        return this.$store.getters['invoice/title']
      },
      set(val) {
        this.$store.commit('invoice/set_title', val)
      }
    },
    type: {
      get() {
        return this.$store.getters['invoice/type']
      },
      set(val) {
        this.$store.commit('invoice/set_type', val)
        this.$store.commit('invoice/set_billing_date_recurring', null)
        this.$store.commit('invoice/set_billing_date_normalize', null)
        this.$store.commit('invoice/set_due_date_recurring', null)
        this.$store.commit('invoice/set_due_date_normalize', null)
        if (val !== 'one-time') {
          this.$store.commit('invoice/set_due_date', null)
          this.$store.commit('invoice/set_billing_date', null)
        }
      }
    },
    billing_date: {
      get() {
        return this.$store.getters['invoice/billing_date']
      },
      set(val) {
        this.$store.commit('invoice/set_billing_date', val)
      }
    },
    billing_date_recurring: {
      get() {
        return this.$store.getters['invoice/billing_date_recurring']
      },
      set(val) {
        this.$store.commit('invoice/set_billing_date_recurring', val.value)
        this.$store.commit('invoice/set_billing_date_normalize', val.name)
      }
    },
    due_date: {
      get() {
        return this.$store.getters['invoice/due_date']
      },
      set(val) {
        this.$store.commit('invoice/set_due_date', val)
      }
    },
    due_date_recurring: {
      get() {
        return this.$store.getters['invoice/due_date_recurring']
      },
      set(val) {
        this.$store.commit('invoice/set_due_date_recurring', val.value)
        this.$store.commit('invoice/set_due_date_normalize', val.name)
      }
    },
    project_id: {
      get() {
        return this.$store.getters['invoice/project_id']
      },
      set(val) {
        this.$store.commit('invoice/set_project', val)
        if (val) {
          this.fetchTasks({
            id: val.hasOwnProperty('id') ? val.id : val,
            status: 'completed'
          })
        } else this.$store.commit('invoice/set_tasks', [])
      }
    },
    service_id: {
      get() {
        return this.$store.getters['invoice/service_id']
      },
      set(val) {
        this.$store.commit('invoice/set_service', val)
      }
    },
    template_id: {
      get() {
        return this.$store.getters['invoice/template_id']
      },
      set(val) {
        this.$store.commit('invoice/set_template', val)
      }
    },
    order_id: {
      get() {
        return this.$store.getters['invoice/order_id']
      },
      set(val) {
        this.$store.commit('invoice/set_order', val)
      }
    },
    billable_from_id: {
      get() {
        return this.$store.getters['invoice/billable_from_id']
      },
      set(val) {
        this.$store.commit('invoice/set_billable_from_id', val)
      }
    },
    billable_to_id: {
      get() {
        return this.$store.getters['invoice/billable_to_id']
      },
      set(val) {
        this.$store.commit('invoice/set_billable_to_id', val)
        this.selected_billable_to = val
        if (val) {
          this.fetchOrders({
            user_id: val.hasOwnProperty('id') ? val.id : val
          })
        } else this.$store.commit('invoice/set_orders', [])
      }
    },
    terms: {
      get() {
        return this.$store.getters['invoice/terms']
      },
      set(val) {
        this.$store.commit('invoice/set_terms', val)
      }
    }
  },
  methods: {
    ...mapActions('invoice', [
      'fetchUsers',
      'fetchOrders',
      'fetchProjects',
      'fetchServices',
      'fetchTemplates',
      'fetchCurrencies',
      'fetchTasks'
    ]),
    getPreview(template) {
      if (template.hasOwnProperty('preview') && template.preview) {
        return template.preview.hasOwnProperty('thumbnail')
          ? template.preview.thumbnail
          : 'https://via.placeholder.com/150x200.png?text=No+Preview'
      }
      return 'https://via.placeholder.com/150x200.png?text=No+Preview'
    },
    getSelected(id) {
      let index = this.users.findIndex((i) => i.id === id)
      return ~index ? this.users[index].fullname : ''
    }
  }
}
</script>

<style lang="scss">
.step-1 {
  .text--red {
    color: red !important;
  }
  .text-shadow-dark {
    color: #fff;
    background-color: grey;
    text-shadow: black;
  }
  .steps-label {
    font-weight: 800;
    font-size: 15px !important;
  }
}
</style>
