<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    :title="title"
    :button2-text="saveButtonLabel"
    @button2="save"
    :main-btn-disabled="!valid"
    :max-width="500"
  >
    <template v-slot:content>
      <v-sheet class="fullwidth px-md-10">
        <v-form v-model="valid" ref="form">
          <v-row
            align="center"
            justify="center"
            :class="mdAndUp ? '' : 'flex-column'"
            dense
          >
            <v-col cols="12" class="d-flex align-center justify-start">
              <label class="subtitle-2 mr-5 primary--text">Tax Type</label>
              <v-radio-group
                mandatory
                row
                v-model="mapping.type"
                hide-details="auto"
                class="mt-0"
              >
                <v-radio value="fixed" label="Fixed"></v-radio>
                <v-radio value="percentage" label="Percentage"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <label class="subtitle-2 primary--text">Tax Name</label>
              <v-text-field
                v-model="mapping.name"
                hide-details="auto"
                dense
                :rules="[requiredRule()]"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <label class="subtitle-2 primary--text">Tax Value</label>
              <vuetify-money
                :rules="[requiredRule(), minRule(0), maxRule(999999999.99)]"
                min="0.00"
                max="999999999.99"
                outlined
                clearable
                dense hide-details="auto"
                value-when-is-empty="0.0"
                v-model="mapping.value"
                class="fullwidth"
                :options="option"
              ></vuetify-money>
            </v-col>
            <v-col cols="12">
              <label class="subtitle-2 primary--text">
                Tax Number (Optional)
              </label>
              <v-text-field
                v-model="mapping.tax_number"
                hide-details="auto"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";
export default {
  name: "TaxDialog",
  components: {
    CustomDialog,
  },
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object },
  },
  computed: {
    isEdit() {
      return this.item ? true : false;
    },
    saveButtonLabel() {
      return this.isEdit ? "Update" : "Save";
    },
    title() {
      return this.isEdit ? "Update Tax" : "Create New Tax";
    },
    option() {
      return {
        locale: "en-US",
        prefix: this.mapping.type === "percentage" ? "%" : "$",
        suffix: "",
        length: 11,
        precision: 2,
      };
    },
  },
  data: () => ({
    dialog: false,
    valid: false,
    mapping: {
      type: "fixed",
      value: 0.0,
      name: null,
      tax_number: null,
    },
  }),
  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.dialog = val;
      if (val && !this.isEdit) {
        this.mapItem(null);
        this.resetValidation()
      }
    },
    item: {
      handler: function (val) {
        this.mapItem(val);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    mapItem(item) {
      this.mapping = {
        id: item ? item.id : null,
        type: item ? item.type : "fixed",
        value: item ? item.value : 0.0,
        name: item ? item.name : null,
        tax_number: item ? item.tax_number : null,
      };
    },
    save() {
      this.$emit(this.isEdit ? "update" : "save", this.mapping);
    },
  },
};
</script>
 <style>
</style>