<template>
  <v-sheet
    color="transparent"
    class="d-flex fullwidth align-center justify-space-between py-2"
  >
    <vuetify-money
      :rules="[requiredRule(), minRule(0), maxRule(999999999.99)]"
      min="0.00"
      max="999999999.99"
      outlined
      clearable
      dense
      hide-details="auto"
      value-when-is-empty="0.0"
      v-model="field"
      class="fullwidth"
      :options="option"
    ></vuetify-money>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          class="align-self-center mx-auto"
          @click="usePercent = !usePercent"
        >
          <v-icon color="primary" v-if="!usePercent">
            mdi-currency-usd-circle
          </v-icon>
          <v-icon color="primary" v-else> mdi-label-percent </v-icon>
        </v-btn>
      </template>
      <span>
        {{ !usePercent ? "Using fixed amount" : "Using percentage" }}
      </span>
    </v-tooltip>
  </v-sheet>
</template>

<script>
import _cloneDeep from "lodash/cloneDeep";

export default {
  name: "InvoiceAddonInput",
  props: {
    value: {
      type: Object,
      default: () => ({ type: "fixed", value: 0.0, show: false }),
    },
  },
  data: () => ({
    item: null,
    usePercent: false,
    showIt: false,
    field: 0.0,
    rules: [(v) => !!v || "This field is set, so it required a value"],
  }),
  computed: {
    option() {
      return {
        locale: "en-US",
        prefix: this.usePercent ? "%" : "$",
        suffix: "",
        length: 11,
        precision: 2,
      };
    },
  },
  watch: {
    show(val) {
      this.showIt = val;
    },
    value: {
      handler: function (val) {
        let item = _cloneDeep(val);
        if (item) {
          this.usePercent = item.type === "fixed" ? false : true;
          this.field = item.value;
          this.showIt = item.show;
        } else {
          this.usePercent = false;
          this.field = 0.0;
          this.showIt = false;
        }
      },
      immediate: true,
      deep: true,
    },
    isPercent(val) {
      this.usePercent = val;
    },
    usePercent: {
      handler: function (val) {
        this.$emit("input", this.getPayload());
      },
      immediate: true,
    },
    showIt: {
      handler: function (val) {
        this.$emit("input", this.getPayload());
      },
      immediate: true,
    },
    field: {
      handler: function (val) {
        this.$emit("input", this.getPayload());
      },
      immediate: true,
    },
  },
  methods: {
    getPayload() {
      return {
        type: this.usePercent ? "percent" : "fixed",
        value: this.field,
        show: this.showIt,
      };
    },
  },
};
</script>

<style>
</style>