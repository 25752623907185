<template>
  <v-card flat elevation="3" class="invoice-maker rounded-10">
    <v-card-title class="title primary--text">
      <span style="font-size: 38px">{{ page_title }}</span>
      <v-spacer></v-spacer>
      <v-btn
        color="transparent"
        elevation="0"
        icon
        :disabled="btnloading"
        @click="$router.go(-1)"
      >
        <v-icon color="primary">mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-stepper v-model="stepper" elevation="0">
        <v-stepper-header
          flat
          class="mx-auto"
          style="box-shadow: none; width: 90%"
        >
          <v-stepper-step
            class="invoice-stepper"
            :key="`step-1`"
            :complete="stepper > 1"
            :step="1"
            :rules="[() => step1_passed]"
          >
            Basic Info
            <small class="primary--text fw-600 mt-1">
              Setup Invoice Details
            </small>
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            class="invoice-stepper"
            :key="`step-2`"
            :complete="stepper > 2"
            :step="2"
            :rules="[() => step2_passed]"
          >
            Set Items
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            class="invoice-stepper"
            :key="`step-3`"
            :complete="stepper > 3"
            :step="3"
            :rules="[() => step3_passed]"
          >
            Customize & Save
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content class="my-0 pa-2" :key="`content-1`" :step="1">
            <InvoiceStep1></InvoiceStep1>
          </v-stepper-content>
          <v-stepper-content class="my-0 pa-2" :key="`content-2`" :step="2">
            <InvoiceStep2 :edit-item="invoice_edit"></InvoiceStep2>
          </v-stepper-content>
          <v-stepper-content class="my-0 pa-2" :key="`content-3`" :step="3">
            <InvoiceStep3></InvoiceStep3>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-card-actions class="px-5 pt-0 pb-5">
      <v-btn
        class="text-none px-5"
        dark
        color="primary"
        v-if="stepper > 1"
        @click="back"
      >
        <v-icon left size="25px">mdi-chevron-left</v-icon> Previous
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="text-none px-5"
        :disabled="!can_proceed"
        color="primary"
        v-if="stepper < 3"
        @click="next"
      >
        Next <v-icon right size="25px">mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn
        @click="is_editting ? updateInvoice() : saveInvoice()"
        class="text-none px-5"
        color="primary"
        :disabled="!step3_passed"
        :loading="btnloading"
        v-if="stepper === 3"
      >
        {{ is_editting ? 'Update Invoice' : 'Create' }}
        <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import request from '@/services/axios_instance'
import InvoiceStep1 from './Step1.vue'
import InvoiceStep2 from './Step2.vue'
import InvoiceStep3 from './Step3.vue'
export default {
  components: {
    InvoiceStep1,
    InvoiceStep2,
    InvoiceStep3
  },
  data: () => ({
    btnloading: false,
    edit_id: null,
    stepper: 1,
    paths: [
      {
        text: 'Dashboard',
        disabled: false,
        route: { name: 'default-content' }
      },
      { text: 'Invoice', disabled: true, route: null },
      { text: 'Generate', disabled: true, route: null }
    ],
    for_type: null,
    for_id: null,
    for_item: null
  }),
  mounted() {
    this.$event.$emit('path-change', this.paths)
    this.$store.commit('set_loader', false)
    this.set_create_mode(true)
  },
  watch: {
    '$route.params.id': {
      handler: function (val) {
        if (val) {
          this.edit_id = val
          this.fetchInvoice(val)
        } else {
          this.$store.commit('invoice/set_billable_from_id', this.user.id)
          this.$store.commit('invoice/set_billable_from_type', 'App\\User')
        }
      },
      immediate: true
    },
    '$route.query': {
      handler: function (val) {
        if (val.hasOwnProperty('for_type') && val.hasOwnProperty('for_id')) {
          this.for_type = val.for_type
          this.for_id = val.for_id
          this.fetchForItem(val.for_type, val.for_id)
        }
        if (val.hasOwnProperty('project_id') && Number(val.project_id) > 0) {
          this.set_project(Number(val.project_id))
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('invoice', [
      'invoice',
      'invoice_edit',
      'is_editting',
      'step1_passed',
      'step2_passed',
      'step3_passed'
    ]),
    page_title() {
      return this.is_editting ? 'Update Invoice' : 'Create Invoice'
    },
    can_proceed() {
      if (this.stepper === 1) {
        return this.step1_passed
      } else if (this.stepper === 2) {
        return this.step2_passed
      }
      return false
    }
  },
  methods: {
    ...mapMutations('invoice', ['set_project', 'set_create_mode']),
    back() {
      this.stepper--
    },
    next() {
      this.stepper++
    },
    fetchInvoice(id) {
      request.get(`api/invoice/${id}`).then(({ data }) => {
        this.$store.commit('invoice/editting_invoice', data)
        // if (data.order_id) {
        //   this.$store.dispatch("invoice/fetchOrders", data.billable_to_id);
        // }
      })
    },
    updateInvoice() {
      this.btnloading = true
      request
        .put(`api/invoice/${this.invoice.id}`, this.invoice)
        .then(() => {
          this.$store.commit('invoice/clear_invoice')
          this.appSnackbar('Invoice updated')
          this.$router.push({ name: 'invoice' })
        })
        .finally(() => {
          this.btnloading = false
        })
    },
    saveInvoice() {
      this.btnloading = true
      request
        .post(`api/invoice`, this.invoice)
        .then(() => {
          this.$store.commit('invoice/clear_invoice')
          this.appSnackbar('Invoice created')
          this.$router.push({ name: 'invoice' })
        })
        .finally(() => {
          this.btnloading = false
        })
    },
    fetchForItem(type, id) {
      request.get(`api/invoice/for/${type}/${id}`).then(({ data }) => {
        this.for_item = data
        this.$store.commit('invoice/set_order', id)
        this.$store.commit('invoice/set_type', 'one-time')
        this.$store.commit(
          'invoice/set_billable_to_id',
          data.hasOwnProperty('from') && data.from ? data.from.id : null
        )
        this.$store.commit(
          'invoice/set_project',
          data.hasOwnProperty('project') && data.project
            ? data.project.id
            : null
        )
      })
    }
  }
}
</script>
<style scoped>
>>> .invoice-stepper .v-stepper__step__step {
  border-radius: 7px !important;
  width: 40px;
  height: 40px;
  font-size: 18px;
  font-weight: 700;
}
>>> .invoice-stepper .v-stepper__label {
  color: #223a6b;
  font-size: 17px;
}
</style>
<style lang="scss" scoped>
.invoice-maker {
  padding: 10px;
}
</style>
