<template>
  <v-row
    align="start"
    justify="center"
    dense
    v-bind="$attrs"
    :class="{ 'rounded-10 mb-3 whitesmoke': !mdAndUp }"
    class="table-row mx-md-12 mx-sm-0"
  >
    <v-col :cols="mdAndUp ? 2 : 6">
      <label v-if="!mdAndUp" class="subtitle-2 primary--text">
        Description
      </label>
      <v-text-field
        v-model="item.description"
        outlined
        dense
        @change="emitChanged"
        placeholder="Item description"
        :rules="requiredRules"
        hide-details="auto"
      >
      </v-text-field>
    </v-col>
    <v-col :cols="mdAndUp ? 2 : 6">
      <label v-if="!mdAndUp" class="subtitle-2 primary--text">Quantity</label>
      <v-text-field
        placeholder="Quantity"
        v-model="item.quantity"
        :rules="[maxRule(999999999.99), minRule(1), requiredRule()]"
        outlined
        dense
        @change="emitChanged"
        hide-details="auto"
        min="1"
        max="999999999.99"
        type="number"
      ></v-text-field>
    </v-col>
    <v-col :cols="mdAndUp ? 2 : 6">
      <label v-if="!mdAndUp" class="subtitle-2 primary--text">Unit</label>
      <v-select
        v-model="item.units"
        outlined
        :rules="requiredRules"
        :items="unit_list"
        dense
        @change="emitChanged"
        item-text="name"
        item-value="value"
        hide-details="auto"
      ></v-select>
    </v-col>
    <v-col :cols="mdAndUp ? 2 : 6">
      <label v-if="!mdAndUp" class="subtitle-2 primary--text">
        Price per unit
      </label>
      <v-text-field
        :rules="[requiredRule(), minRule(0), maxRule(999999999.99)]"
        outlined
        dense
        @change="emitChanged"
        hide-details="auto"
        min="0"
        max="999999999.99"
        type="number"
        v-model="item.price_per_unit"
      ></v-text-field>
    </v-col>
    <v-col :cols="mdAndUp ? 2 : 6">
      <label v-if="!mdAndUp" class="subtitle-2 primary--text">Total</label>
      <vuetify-money
        :value="total"
        readonly
        outlined
        dense
        hide-details="auto"
        value-when-is-empty="0.0"
        :options="option_money"
      ></vuetify-money>
    </v-col>
    <v-col :cols="mdAndUp ? 2 : 6" class="d-flex align-center justify-center">
      <v-menu v-model="menu_1" left :close-on-content-click="false">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
                color="primary"
                depressed
                class="rounded-10 mr-1"
              >
                TAX
              </v-btn>
            </template>
            <span>Add Line Tax</span>
          </v-tooltip>
        </template>
        <v-card width="400" flat>
          <v-card-title class="primary fw-700 white--text">
            Add Taxes
          </v-card-title>
          <v-card-text>
            <v-row dense align="center" justify="center">
              <v-col cols="1"></v-col>
              <v-col cols="3" class="primary--text text-center fs-15 fw-900">
                Rate
              </v-col>
              <v-col cols="4" class="primary--text text-center fs-15 fw-900">
                Tax Name
              </v-col>
              <v-col cols="4" class="primary--text text-center fs-15 fw-900">
                Tax Number (Optional)
              </v-col>
            </v-row>
            <v-row
              dense
              align="center"
              justify="center"
              v-for="tax in invoice_save_taxes"
              :key="tax.id"
            >
              <v-col cols="1" class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="selected_tax_id"
                  hide-details="auto"
                  :value="tax.id"
                  class="ma-0 pa-0"
                ></v-checkbox>
              </v-col>
              <v-col cols="3" class="primary--text text-center fs-15 fw-500">
                {{ tax.value }} {{ tax.type === 'fixed' ? '$' : '%' }}
              </v-col>
              <v-col cols="4" class="primary--text text-center fs-15 fw-500">
                {{ tax.name }}
              </v-col>
              <v-col cols="4" class="primary--text text-center fs-15 fw-500">
                {{ tax.tax_number || '-' }}
              </v-col>
            </v-row>
            <Empty
              v-if="!invoice_save_taxes.length"
              headline="No save tax yet"
            ></Empty>
            <v-row
              align="center"
              justify="center"
              class="mb-1 flex-column mt-3 text-center"
            >
              <v-btn
                depressed
                text
                width="90%"
                @click.stop="$emit('add-new-tax')"
                class="
                  text-none
                  mt-5
                  secondary--text
                  rounded-10
                  border-dashed
                  border-color--primary
                "
              >
                Add another tax
              </v-btn>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-block border-top-1 border-color--grey">
            <!-- <v-row dense class="mb-2" align="center" justify="center">
              <v-checkbox
                :disabled="!selected_tax"
                v-model="all_tax"
                hide-details="auto"
                :true-value="true"
                :false-value="false"
                label="Apply taxes to all line items"
              ></v-checkbox>
            </v-row> -->
            <v-row dense align="center" justify="end" class="py-2">
              <v-btn
                @click.stop="menu_1 = false"
                depressed
                color="primary"
                class="mx-1 text-none"
              >
                Cancel
              </v-btn>
              <v-btn
                @click.stop="applyTax"
                depressed
                color="primary"
                class="mx-1 text-none"
              >
                Apply Tax
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-tooltip color="accent" class="toggle-btn" top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="primary"
            class="rounded-10"
            v-on="on"
            depressed
            @click="$emit('delete-item')"
          >
            <v-icon size="20px"> mdi-delete </v-icon>
          </v-btn>
        </template>
        <span>Remove Item</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
export default {
  inheritAttrs: false,
  name: 'InvoiceRowInput',
  props: {
    value: { type: Object }
  },
  computed: {
    ...mapGetters('invoice', ['currency', 'unit_list', 'invoice_save_taxes']),
    selected_tax() {
      return this.selected_tax_id
        ? this.invoice_save_taxes.find((i) => i.id === this.selected_tax_id)
        : null
    },
    total() {
      return this.calculateItemTotal(this.item)
    },
    option_money() {
      return {
        locale: 'en-US',
        prefix: '$',
        suffix: '',
        length: 11,
        precision: 2
      }
    },
    option_number() {
      return {
        locale: 'en-US',
        prefix: '',
        suffix: '',
        length: 11,
        precision: 2
      }
    },
    isValid() {
      if (!this.item) return false
      if (!this.item.description) return false
      if (!this.item.quantity || Number(this.item.quantity) < 0.01) return false
      if (!this.item.price_per_unit || Number(this.item.price_per_unit) < 0)
        return false
      return !this.item.total ? false : true
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.item = val ? _.cloneDeep(val) : _.cloneDeep(this.default)
      },
      deep: true,
      immediate: true
    },
    item: {
      handler: function (val) {
        // this.$emit("input", val);
      },
      deep: true,
      immediate: true
    }
  },
  data: () => ({
    selected_tax_id: null,
    menu_1: false,
    item: {
      isValid: false,
      description: null,
      quantity: 1,
      unit: 'hour',
      price_per_unit: 0.0,
      total: 0.0,
      props: null,
      tax: {
        id: null,
        show: false,
        value: 0.0,
        type: 'fixed',
        name: null,
        tax_number: null
      }
    },
    default: {
      isValid: false,
      description: null,
      quantity: 1,
      units: 'hour',
      price_per_unit: 0.0,
      total: 0.0,
      props: null,
      tax: {
        id: null,
        show: false,
        value: 0.0,
        type: 'fixed',
        name: null,
        tax_number: null
      }
    }
  }),
  methods: {
    emitChanged() {
      // console.log(this.item);
      this.$emit('row-changed', this.item)
    },
    applyTax() {
      this.$set(this.item, 'tax', {
        id: this.selected_tax_id || null,
        show: this.selected_tax_id ? true : false,
        value: this.selected_tax_id ? this.selected_tax.value : 0.0,
        type: this.selected_tax_id ? this.selected_tax.type : 'fixed',
        name: this.selected_tax_id ? this.selected_tax.name : null,
        tax_number: this.selected_tax_id ? this.selected_tax.tax_number : null
      })
      this.menu_1 = false
      this.$nextTick(() => this.emitChanged())
    },
    calculateItemTotal(item) {
      if (
        !item ||
        isNaN(Number(item.quantity)) ||
        isNaN(Number(item.price_per_unit))
      ) {
        return 0.0
      }
      return Number(item.quantity) * Number(item.price_per_unit)
    }
  }
}
</script>

<style></style>
