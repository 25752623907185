<template>
  <v-card flat class="mb-0 step-2" color="lighten-1" min-height="400px">
    <v-card-text class="px-md-12 px-sm-1">
      <v-form v-model="valid">
        <v-row dense class="mx-md-12 mx-sm-0" v-if="mdAndUp">
          <v-col
            :cols="mdAndUp ? 2 : 6"
            class="table-header subtitle-2 text-center"
          >
            Description
          </v-col>
          <v-col
            :cols="mdAndUp ? 2 : 6"
            class="table-header subtitle-2 text-center"
          >
            Quantity
          </v-col>
          <v-col
            :cols="mdAndUp ? 2 : 6"
            class="table-header subtitle-2 text-center"
          >
            Unit
          </v-col>
          <v-col
            :cols="mdAndUp ? 2 : 6"
            class="table-header subtitle-2 text-center"
          >
            Price Per Unit
          </v-col>
          <v-col
            :cols="mdAndUp ? 2 : 6"
            class="table-header subtitle-2 text-center"
          >
            Total
          </v-col>
          <v-col
            :cols="mdAndUp ? 2 : 6"
            class="table-header subtitle-2 text-center"
          >
          </v-col>
        </v-row>
        <template v-if="local_items.length">
          <InvoiceRowInput
            v-for="(item, index) in local_items"
            :key="index"
            :value="local_items[index]"
            @row-changed="itemChanged($event, index)"
            @add-new-tax="tax_dialog = true"
            @delete-item="deleteItem(index)"
          ></InvoiceRowInput>
        </template>
        <v-row dense class="table-row mx-md-12 mx-sm-0 mb-4">
          <v-col
            v-if="on_max_items"
            cols="12"
            class="d-flex align-center justify-center"
          >
            <span class="mx-auto text--red"> Max of {{ maxItems }} items </span>
          </v-col>
          <v-col
            :cols="mdAndUp && project_id > 0 && tasks.length ? 6 : 12"
            class="d-flex align-center justify-center"
          >
            <v-btn
              :disabled="on_max_items"
              color="transparent"
              depressed
              block
              outlined
              class="text-none mt-3 primary--text mx-auto border-dashed"
              @click="addNewItem"
            >
              <v-icon left>mdi-plus-box-outline</v-icon>
              <span> Add new item </span>
            </v-btn>
          </v-col>
          <v-col :cols="mdAndUp ? 6 : 12" v-if="project_id > 0 && tasks.length">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="on_max_items"
                  color="transparent"
                  depressed
                  block
                  outlined
                  class="text-none mt-3 primary--text mx-auto border-dashed"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>mdi-plus-box-outline</v-icon>
                  <span> Add items from tasks </span>
                </v-btn>
              </template>
              <v-list dense max-height="350" style="overflow-y: auto">
                <v-list-item
                  v-for="item in tasks"
                  :key="item.id"
                  @click="addFromTasks(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="d-block">
                      (ID: {{ item.id }}), Title:
                      {{ item.title | ucwords }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="d-block">
                      Hours: {{ item.timer_stats.readable }} | Status:
                      {{ item.status | ucwords }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col
            v-show="errors.length"
            cols="12"
            class="text--red fw-400 ls-1 fs-13 pt-3"
            v-html="errors_normalize"
          ></v-col>
        </v-row>
        <v-row dense class="mx-md-12 mx-sm-0">
          <v-spacer />
          <v-col :cols="mdAndUp ? 2 : 12" class="d-flex justify-end">
            <span class="subtitle-1 font-weight-bold primary--text text-center">
              Subtotal: {{ subtotal | money(currency) }}
            </span>
          </v-col>
        </v-row>
        <v-row dense class="mx-md-12 mx-sm-0">
          <v-spacer />
          <v-col :cols="mdAndUp ? 2 : 12" class="d-flex justify-end">
            <span class="subtitle-1 font-weight-bold primary--text text-center">
              Total Tax: {{ tax_amount | money(currency) }}
            </span>
          </v-col>
        </v-row>
        <v-row
          align="start"
          justify="end"
          class="table-addons mx-md-12 mx-sm-0"
          dense
        >
          <v-col :cols="mdAndUp ? 3 : 12" class="d-flex flex-column">
            <v-btn
              @click="local_discount.show = !local_discount.show"
              color="primary"
              block
              depressed
              style="min-width: unset !important"
              width="100%"
              class="btn-addons caption text-none"
            >
              <span style="font-weight: 700; font-size: 17px !important">
                {{ local_discount.show ? "Remove" : "Add" }} Discount
              </span>
            </v-btn>
            <InvoiceAddonInput
              v-if="local_discount.show"
              v-model="local_discount"
            ></InvoiceAddonInput>
          </v-col>
          <v-col :cols="mdAndUp ? 3 : 12">
            <v-btn
              @click="local_shipping_fee.show = !local_shipping_fee.show"
              color="primary"
              block
              depressed
              style="min-width: unset !important"
              width="100%"
              class="btn-addons caption text-none"
            >
              <span style="font-weight: 700; font-size: 17px !important">
                {{ local_shipping_fee.show ? "Remove" : "Add" }} Shipping
              </span>
            </v-btn>
            <InvoiceAddonInput
              v-if="local_shipping_fee.show"
              v-model="local_shipping_fee"
            ></InvoiceAddonInput>
          </v-col>
        </v-row>
        <v-row dense class="mx-md-12 mx-sm-0 mb-4">
          <v-spacer />
          <v-col :cols="mdAndUp ? 2 : 12" class="d-flex justify-end">
            <span
              class="fw-700 fs-20 font-weight-bold primary--text text-center"
            >
              Total : {{ total_after_addons | money(currency) }}
            </span>
          </v-col>
        </v-row>

        <v-row dense class="mx-md-12 mx-sm-0">
          <v-col :cols="12" class="mb-2">
            <label class="primary--text subtitle-2 fw-700 fs-18"> Notes </label>
            <v-textarea
              outlined 
              v-model="notes"
              placeholder="Notes"
              clearable
              clear-icon="mdi-close-circle-outline"
              auto-grow
              :rows="3"
              hide-details="auto"
              dense
            ></v-textarea>
          </v-col> 
        </v-row>
      </v-form>
    </v-card-text>

    <TaxDialog
      v-model="tax_dialog"
      :item="null"
      @save="handleSaveNewTax"
    ></TaxDialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _cloneDeep from "lodash/cloneDeep";
import InvoiceAddonInput from "../components/InvoiceAddonInput.vue";
import { invoice_maker_mixins } from "./invoice_maker_mixins";
import InvoiceRowInput from "../components/InvoiceRowInput.vue";
import TaxDialog from "../components/TaxDialog.vue";
export default {
  name: "InvoiceStep2",
  mixins: [invoice_maker_mixins],
  components: { InvoiceAddonInput, InvoiceRowInput, TaxDialog },
  props: {
    editItem: { type: Object },
  },
  data: () => ({
    tax_dialog: false,
    valid: false,
    global_tax: null,
    all_tax: false,
    checkbox_tax: false,
    row: {
      isValid: false,
      description: null,
      quantity: 1,
      units: "hour",
      price_per_unit: 0.0,
      total: 0.0,
      tax: {
        id: null,
        show: false,
        value: 0.0,
        type: "fixed",
        name: null,
        tax_number: null,
      },
    },
  }),
  mounted() {
    if (!this.is_editting) {
      this.addNewItem();
    }
    this.fetchTaxes();
  },
  watch: {
    editItem: {
      handler: function (val) {
        this.setEditInvoice(val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("invoice", [
      "currency",
      "invoice",
      "order_id",
      "orders",
      "project_id",
      "tasks",
      "items",
      "is_editting",
      "unit_list",
    ]),
    notes: {
      get() {
        return this.$store.getters["invoice/notes"];
      },
      set(val) {
        this.$store.commit("invoice/set_notes", val);
      },
    },
  },
  methods: {
    ...mapActions("invoice", ["fetchTaxes", "saveTaxes"]),
    addNewItem() {
      this.addItem(_cloneDeep(this.row));
    },
    handleSaveNewTax(payload) {
      this.saveTaxes({
        payload: payload,
        cb: () => {
          this.tax_dialog = false;
          this.appSnackbar( "New tax saved");
        },
      });
    },
  },
};
</script>
<style scoped>
.tax-table-header {
  color: #223a6b;
  font-size: 11px !important;
}
.add-tax-popup {
  left: unset !important;
  right: 340px;
  top: 285px !important;
}
@media all and (max-width: 1110px) and (min-width: 915px) {
  .add-tax-popup {
    right: 244px !important;
    top: 291px !important;
  }
}
@media all and (max-width: 915px) {
  .add-tax-popup {
    right: 10% !important;
    left: unset !important;
  }
}
</style>
<style lang="scss">
.step-2 {
  .text--red {
    color: red !important;
  }
  .text-shadow-dark {
    color: #fff;
    background-color: grey;
    text-shadow: black;
  }
  .table-header {
    background-color: #223a6b;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 18px !important;
    font-weight: 700;
    .v-icon {
      color: blanchedalmond;
    }
  }
}
</style>