import _ from 'lodash';
export const invoice_maker_mixins = {
    data: () => ({
        local_items: [],
        maxItems: 5,
        local_tax: {
            type: 'fixed',
            value: 0.00,
            show: false
        },
        local_discount: {
            type: 'fixed',
            value: 0.00,
            show: false
        },
        local_shipping_fee: {
            type: 'fixed',
            value: 0.00,
            show: false
        },
        errors: []
    }),
    watch: {
        local_items: {
            handler: function (val) {
                this.calculateItemsTotal();
                this.validateItems();
                this.$store.commit('invoice/set_items', _.cloneDeep(val));
            },
            immediate: true,
            deep: true
        },
        local_tax: {
            handler: function (val) {
                this.$store.commit('invoice/set_tax', _.cloneDeep(val));
            },
            immediate: true,
            deep: true
        },
        local_shipping_fee: {
            handler: function (val) {
                this.$store.commit('invoice/set_shipping_fee', _.cloneDeep(val));
            },
            immediate: true,
            deep: true
        },
        local_discount: {
            handler: function (val) {
                this.$store.commit('invoice/set_discount', _.cloneDeep(val));
            },
            immediate: true,
            deep: true
        },
    },
    computed: {
        errors_normalize() {
            const input = _.cloneDeep(this.errors);
            if (!input.length) {
                return '';
            }
            if (input.length === 1) {
                return input.join(', ') + '  has an invalid/required value for description, price per unit or quantity';
            }
            if (input.length === 2) {
                return input.join(' and ') + '  has an invalid/required value for description, price per unit or quantity';
            }
            const last = input.pop();
            return input.join(', ') + ' and ' + last + '  has an invalid/required value for description, price per unit or quantity';
        },
        on_max_items() {
            return this.local_items.length >= this.maxItems;
        },
        subtotal() {
            let each_item_total = this.local_items.map(i => { return { each_total: Number(i.quantity) * Number(i.price_per_unit) } })
            return _.sumBy(each_item_total, 'each_total');
        },
        tax_amount() {
            if (!this.local_items.length) return 0.00;
            return _.sumBy(
                this.local_items.map(i => {
                    let subtotal = (Number(i.quantity) * Number(i.price_per_unit))
                    return {
                        total: i.tax && i.tax.type !== 'fixed' ? ((subtotal * Number(i.tax.value)) / 100) : Number(i.tax.value)
                    }
                }),
                'total');
        },
        discount_amount() {
            if (!this.local_discount.show) {
                return 0.00;
            }
            return this.local_discount.type !== 'fixed' ? (this.subtotal * Number(this.local_discount.value)) / 100 : Number(this.local_discount.value);
        },
        shipping_fee_amount() {
            if (!this.local_shipping_fee.show) {
                return 0.00;
            }
            return this.local_shipping_fee.type !== 'fixed' ? (this.subtotal * Number(this.local_shipping_fee.value)) / 100 : Number(this.local_shipping_fee.value);
        },
        total_after_addons() {
            return (this.subtotal - Math.abs(this.discount_amount)) + this.tax_amount + this.shipping_fee_amount;
        },
    },
    methods: {
        calculateItemTotal(item) {
            if (isNaN(Number(item.quantity)) || isNaN(Number(item.price_per_unit))) {
                return 0.0;
            }
            return Number(item.quantity) * Number(item.price_per_unit);
        },
        calculateItemsTotal() {
            if (this.local_items.length) {
                const _this = this;
                _this.local_items.forEach(function (item, index) {
                    this[index].total = _this.calculateItemTotal(item)
                }, _this.local_items);
            }
        },
        addItems(items) {
            this.local_items.push(...items);
        },
        addItem(item) {
            this.local_items.push(item);
        },
        deleteItem(index) {
            this.local_items.splice(index, 1);
        },
        updateItem(item, index) {
            this.local_items.splice(index, 1, item);
        },
        clearItems() {
            this.local_items = [];
        },
        itemChanged(item, index) {
            let item_updated = _.cloneDeep(item);
            item_updated.total = this.calculateItemTotal(item);
            this.updateItem(item_updated, index);
            this.validateItems()
        },
        validateItems() {
            this.errors = [];
            let _this = this;
            this.local_items.forEach(function (item, index) {
                if (!item.description || item.quantity < 0) {
                    _this.errors.push(`Item ${index + 1}`);
                }
            }, this.local_items);
        },
        setEditInvoice(invoice) {
            this.local_items = invoice ? _.cloneDeep(invoice.items) : [];
            this.local_tax = invoice
                ? _.cloneDeep(invoice.tax)
                : { type: "fixed", value: 0.0, show: false };
            this.local_discount = invoice
                ? _.cloneDeep(invoice.discount)
                : { type: "fixed", value: 0.0, show: false };
            this.local_shipping_fee = invoice
                ? _.cloneDeep(invoice.shipping_fee)
                : { type: "fixed", value: 0.0, show: false };
            this.calculateItemsTotal();
            this.validateItems()
        },
        addFromOrder(order) {
            let item = {
                description: order.product_name,
                quantity: Number(order.quantity),
                units: "order",
                price_per_unit: Number(order.buy_price),
                total: Number(order.buy_price) * Number(order.quantity),
                props: {
                    source: 'order',
                    id: order.id
                },
                tax: {
                    id: null,
                    show: false,
                    value: 0.0,
                    type: "fixed",
                    name: null,
                    tax_number: null,
                },
            }
            this.addItem(item);
            this.$nextTick(this.validateItems())

        },
        addFromTasks(task) {
            let qty = Number(task.timer_stats.hrs) + (((100 * Number(task.timer_stats.min)) / 60) / 100);
            let item = {
                isValid: false,
                description: task.title,
                quantity: qty,
                units: "hour",
                price_per_unit: 0.00,
                total: 0.00,//Number(order.buy_price) * Number(order.quantity),
                props: {
                    source: 'task',
                    id: task.id
                },
                tax: {
                    id: null,
                    show: false,
                    value: 0.0,
                    type: "fixed",
                    name: null,
                    tax_number: null,
                },
            }
            this.addItem(item);
            this.$nextTick(this.validateItems())

        }
    }
}