<template>
  <v-card flat class="mb-0 step-3" color="lighten-1" min-height="400px">
    <v-card-text class="px-md-12 px-sm-0">
      <v-row cols="12" class="mb-2 d-block">
        <v-divider class="my-4 pa-3 halfwidth mx-auto"></v-divider>
        <div class="d-flex justify-center fs-22 primary--text fw-600 mb-3">
          Select Invoice Template
        </div>
        <template v-if="templates.length">
          <v-slide-group
            class="step-3-invoice"
            mandatory
            v-model="template_id"
            show-arrows
            next-icon="mdi-skip-next-circle"
            prev-icon="mdi-skip-previous-circle"
          >
            <v-slide-item
              v-for="temp in templates"
              :key="temp.id"
              :value="temp.id"
              v-slot:default="{ active, toggle }"
            >
              <v-card
                :color="active ? 'primary' : 'white'"
                @click="toggle"
                height="200"
                class="ma-3 d-flex align-center justify-center pa-2"
                width="150"
              >
                <img v-lazy="temp.preview" style="max-width: 100%" />
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </template>
        <Empty headline="No available templates yet" v-else></Empty>
      </v-row>
      <v-row
        dense
        align="stretch"
        justify="space-between"
        v-if="selectedTemplate"
      >
        <v-col cols="12" md="8" sm="12">
          <v-sheet>
            <component
              v-if="selectedTemplate.component.includes('InvoiceDefault')"
              :is="selectedTemplate.component"
              :create-mode="createMode"
              v-model="colors"
            ></component>
            <Empty
              min-height="400"
              headline="No template found!"
              v-else
            ></Empty>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="4" sm="12" class="pa-3">
          <v-sheet
            class="
              d-flex
              my-2
              pa-1
              fullwidth
              align-center
              justify-space-between
            "
          >
            <span class="fw-900 fs-18 primary--text"> Customize Template </span>
            <v-tooltip left>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="secondary rounded-5"
                  icon
                  @click="resetToDefault"
                  tile
                >
                  <v-icon color="white">mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Reset to default</span>
            </v-tooltip>
          </v-sheet>
          <v-sheet v-if="colors">
            <v-card flat class="border-color--primary border-2 rounded-5 mb-1">
              <v-card-title
                class="subtitle-2 cursor-pointer primary--text"
                @click="showLogo = !showLogo"
              >
                <span> Logo </span>
                <v-spacer />
                <v-icon> mdi-menu-down </v-icon>
              </v-card-title>
              <v-file-input
                hide-details="auto"
                class="d-none"
                ref="logoFileUpload"
                v-model="logoFileUpload"
                @change="onLogoChanged"
                accept="image/*"
              ></v-file-input>
              <v-expand-transition>
                <v-card-text
                  class="d-flex align-center justify-center"
                  v-if="showLogo"
                >
                  <v-hover v-slot:default="{ hover }">
                    <div>
                      <v-overlay absolute :value="hover">
                        <v-btn
                          class="text-none"
                          color="primary"
                          depressed
                          @click="$refs.logoFileUpload.$refs.input.click()"
                        >
                          <v-icon color="white" left>mdi-image</v-icon> Replace
                          Logo
                        </v-btn>
                      </v-overlay>
                      <v-avatar tile height="100" width="200">
                        <v-img
                          contain
                          :key="invoice_logo"
                          :src="invoice_logo"
                        ></v-img>
                      </v-avatar>
                    </div>
                  </v-hover>
                </v-card-text>
              </v-expand-transition>
            </v-card>
            <template v-for="(item, index) in colors">
              <v-card
                flat
                class="border-color--primary border-2 rounded-5 mb-1"
                :key="index"
              >
                <v-card-title
                  class="subtitle-2 cursor-pointer primary--text"
                  @click="colors[index].show = !colors[index].show"
                >
                  <span> {{ item.name | ucwords }} </span>
                  <v-spacer />
                  <v-avatar
                    size="20"
                    class="rounded-5 border-1 border-color--grey"
                    :color="colors[index].color"
                  ></v-avatar>
                  <v-icon> mdi-menu-down </v-icon>
                </v-card-title>
                <v-expand-transition>
                  <v-card-text v-show="colors[index].show">
                    <v-color-picker
                      dense
                      class="mx-auto"
                      mode="hexa"
                      v-model="colors[index].color"
                      canvas-height="50"
                    ></v-color-picker>
                  </v-card-text>
                </v-expand-transition>
              </v-card>
            </template>
          </v-sheet>
          <Empty
            v-else
            icon="mdi-invert-colors-off"
            headline="Theming not available for this template"
          ></Empty>
          <v-sheet
            v-if="colors"
            class="d-flex my-2 pa-1 fullwidth align-center justify-start"
          >
            <label
              class="
                primary--text
                fw-700
                fs-14
                d-flex
                align-center
                justify-start
              "
            >
              <v-checkbox
                v-model="save_colors"
                :true-value="true"
                :false-value="false"
                hide-details="auto"
                class="mt-0"
              >
              </v-checkbox>
              Remember these colors for this template
            </label>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'
import InvoiceDefault1 from '../../InvoiceTemplates/templates/InvoiceDefault1.vue'
import InvoiceDefault2 from '../../InvoiceTemplates/templates/InvoiceDefault2.vue'
import InvoiceDefault3 from '../../InvoiceTemplates/templates/InvoiceDefault3.vue'
import InvoiceDefault4 from '../../InvoiceTemplates/templates/InvoiceDefault4.vue'
import InvoiceDefault5 from '../../InvoiceTemplates/templates/InvoiceDefault5.vue'
export default {
  name: 'InvoiceStep3',
  components: {
    InvoiceDefault1,
    InvoiceDefault2,
    InvoiceDefault3,
    InvoiceDefault4,
    InvoiceDefault5
  },
  data: () => ({
    showLogo: false,
    logoFileUpload: null
  }),
  created() {
    this.getInvoiceLogo()
  },
  computed: {
    ...mapGetters('invoice', [
      'templates',
      'currency',
      'createMode',
      'preview',
      'preview_state',
      'invoice',
      'sub_total',
      'shipping_amount',
      'discount_amount',
      'total_after_addons',
      'invoice_logo',
      'selectedTemplate'
    ]),
    // html() {
    //   if (this.preview && this.preview.trim() !== "")
    //     return this.getBlobURL(this.preview, "text/html");
    //   return "";
    // },
    save_colors: {
      get() {
        return this.$store.getters['invoice/save_colors']
      },
      set(val) {
        this.$store.commit('invoice/set_save_colors', val)
      }
    },
    colors: {
      get() {
        return this.$store.getters['invoice/colors']
      },
      set(val) {
        this.$store.commit('invoice/set_colors', val)
      }
    },
    template_id: {
      get() {
        return this.$store.getters['invoice/template_id']
      },
      set(val) {
        this.$store.commit('invoice/set_template', val)
      }
    }
  },
  watch: {
    template_id: {
      handler: function (val) {
        if (val && this.selectedTemplate) {
          this.$store.commit(
            'invoice/set_colors',
            _.cloneDeep(this.selectedTemplate.defaults)
          )
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapActions('invoice', ['getInvoiceLogo', 'setInvoiceLogo']),
    resetToDefault() {
      if (this.selectedTemplate) {
        this.$store.commit(
          'invoice/set_colors',
          _.cloneDeep(this.selectedTemplate.colors)
        )
      }
    },
    // onIframeLoad(iframe) {
    //   if (iframe) {
    //     iframe.height = iframe.contentWindow.document.body.scrollHeight + "px";
    //   }
    // },
    // displayTemplate(temp) {
    //   console.log(temp);
    //   this.template_content = temp.template;
    // },
    // getPreview(template) {
    //   if (template.hasOwnProperty("preview") && template.preview) {
    //     return template.preview.hasOwnProperty("thumbnail")
    //       ? template.preview.thumbnail
    //       : "https://via.placeholder.com/150x200.png?text=No+Preview";
    //   }
    //   return "https://via.placeholder.com/150x200.png?text=No+Preview";
    // },
    // getBlobURL(code, type) {
    //   const blob = new Blob([code], { type });
    //   return URL.createObjectURL(blob);
    // },
    onLogoChanged(file) {
      if (
        file &&
        ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].includes(
          file.type
        )
      ) {
        if (file && file.size <= this.settings.fileSizeMaxUploadLimit) {
          let formData = new FormData()
          formData.append('image', file)
          this.setInvoiceLogo({
            payload: formData,
            cb: () => {
              this.appSnackbar('Invoice logo updated', 'success')
            }
          })
        } else {
          this.appSnackbar(
            `File size exceed ${this.settings.fileSizeMaxUploadInMB}MB`,
            'error'
          )
        }
      } else {
        this.appSnackbar(
          `File should be an image type (JPEG,PNG or GIF)`,
          'error'
        )
      }
    }
  }
}
</script>
<style scoped>
>>> .step-3-invoice .v-slide-group__content {
  justify-content: center !important;
}
</style>
<style lang="scss">
.step-3 {
  .text--red {
    color: red !important;
  }
  .text-shadow-dark {
    color: #fff;
    background-color: grey;
    text-shadow: black;
  }
  .table-header {
    background-color: #223a6b;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    .v-icon {
      color: blanchedalmond;
    }
  }
  #iframe {
    width: 100%;
    min-height: 100vh;
  }
}
@media all and (max-width: 600px) {
  .template-color-picker {
    display: flex;
    justify-content: center !important;
  }
}
</style>
